
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'FootAbout',
  setup () {
    const year = computed(() => {
      const date = new Date()
      return date.getFullYear()
    })

    const description =
        ref('SesquiterPre is an online tool dedicated to the prediction of the properties of sesquiterpenoids. ' +
            'It currently contains predicted values for the enthalpy of combustion, ' +
            'density and energy density of 292 sesquiterpene molecules as well as experimental ' +
            'values for the density of 1567 cyclic compounds.'
        )

    return {
      year,
      description
    }
  }
})

<template>
  <div id="header">
    <div class="container">
      <div id="title">
        <div id="logo-group">
          <div class="icon"/>
          <a href="#" class="db-name">SesquiterPre</a>
        </div>
      </div>
      <HeaderButtons />
    </div>
  </div>
</template>

<script>
import HeaderButtons from '@/components/Header/HeaderButtons'
export default {
  name: 'HeaderIndex',
  components: { HeaderButtons }
}
</script>

<style scoped>

#header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 75px;
  background-color: #a71725;
}

#header .container {
  position: relative;
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

#title {
  color: aliceblue;
  font-size: 1.5rem;
  font-weight: bolder;
}

#logo-group {
  display: flex;
  /* align-content: center; */
  /* flex-wrap: wrap; */
  flex-direction: row;
  align-items: center;
}

.icon {
  background-image: url("../../../public/logo_red_x64.ico");
  height: 64px;
  width: 64px;
  margin-bottom: 10px;
}

.db-name {
  font-family: fantasy;
  color: white !important;
  font-size: 2.8rem;
  margin-left: 1rem;
  user-select: none;
}

a {
  text-decoration: none;
  color: aliceblue;
}

a:hover {
  color: white !important;
}

</style>

<template>
  <div class="navi-groups">
    <router-link to="/">
      <div class="navi-group">
        <div class="navi-icon home"/>
        <div class="navi-button">Home</div>
      </div>
    </router-link>
    <router-link to="/run">
       <div class="navi-group">
         <div class="navi-icon run"/>
         <div class="navi-button">Run</div>
       </div>
    </router-link>
    <router-link to="/help">
     <div class="navi-group">
       <div class="navi-icon help"/>
       <div class="navi-button">Help</div>
     </div>
    </router-link>
    <router-link to="/contact">
      <div class="navi-group">
        <div class="navi-icon contact"/>
        <div class="navi-button">contact</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'HeaderButtons'
}
</script>

<style scoped>

.navi-groups {
  display: flex;
  flex-direction: row;
}

.navi-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75px;
  width: 80px;
}

.navi-group:hover {
  background-color: #982e37;
  border-bottom: 2px solid #b65b64;
  box-sizing: border-box;
}

.navi-icon {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
}

.navi-button {
  color: #f7f5f4;
  font-size: 1rem;
  font-weight: bold;
}

.navi-icon.home {
  background-image: url("../../assets/icons/house-solid.svg");
}

.navi-icon.run {
  background-image: url("../../assets/icons/computer-solid.svg");
}

.navi-icon.help {
  background-image: url("../../assets/icons/patch-question-fill.svg");
}

.navi-icon.contact {
  background-image: url("../../assets/icons/mailbox2.svg");
}

a {
  text-decoration: none;
}

a.active .navi-group {
  background-color: #7c1420;
}

</style>

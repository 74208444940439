import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a622f6b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "foot" }
const _hoisted_2 = { class: "my-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FootAbout = _resolveComponent("FootAbout")!
  const _component_FootLinks = _resolveComponent("FootLinks")!
  const _component_FoolTools = _resolveComponent("FoolTools")!
  const _component_FootMap = _resolveComponent("FootMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FootAbout, { class: "component" }),
      _createVNode(_component_FootLinks, { class: "component" }),
      _createVNode(_component_FoolTools, { class: "component" }),
      _createVNode(_component_FootMap)
    ])
  ]))
}
<template>
  <HeaderIndex />
  <router-view/>
  <FootView />
</template>

<script>
import HeaderIndex from '@/components/Header/HeaderIndex'
import FootView from '@/components/Foot/FootView'
import './assets/vendor/bootstrap/css/bootstrap.min.css'

export default {
  components: { FootView, HeaderIndex }
}
</script>

<style>

body {
  margin: 0;
}

</style>

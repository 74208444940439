
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FoolTools',
  setup () {
    const imgs = [
      { src: 'vue.svg', href: 'https://github.com/vuejs/vue', key: 503 },
      { src: 'fastapi.svg', href: 'https://github.com/tiangolo/fastapi', key: 312 },
      { src: 'sklearn.svg', href: 'https://scikit-learn.org/stable/index.html', key: 676 },
      { src: 'gaussian.png', href: 'https://gaussian.com', key: 677 },
      { src: 'multiwfn.png', href: 'http://sobereva.com/multiwfn/index.html', key: 678 }
    ]
    return {
      imgs
    }
  }
})
